import React, { ReactElement, useEffect } from 'react';
import { Layout } from '../components/Layout/Layout';
import { Intro } from '../modules/Intro/Intro';

const SendReviewPage = (): ReactElement => (
    useEffect(()=>{

      let link = '', random = Math.random();
      if (random < 0.2) {
        // option 1: chance 0.0–0.499...
        link = 'https://www.google.com/search?q=sketchwow+customer+service+reviews+trustpilot.com';
      } else if(random < 0.6) {
        // option 2: chance 0.50—0.7499...
        link = 'https://reviews.capterra.com/new/347957/26b15541-66b9-4baf-994c-da4c250ea6a3';
      } else {
        // option 3: chance 0.75–0.99...
        link = 'https://www.g2.com/products/sketchwow/reviews';
      }


        setTimeout(function(){
            window.location.replace(link)
        }, 100)
        

        }, []),

        <Layout>
        <Intro
      title={
        <> </>
      }
      subTitle={<>Thank You</>}
      
    />
  </Layout>
);

export default SendReviewPage;
